import React from 'react';
import type { SemanticWIDTHS } from 'semantic-ui-react';
import { Button, Header } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import type { FC } from 'react';

import FeatureFlags from 'src/api/FeatureFlags';
import ClickToCallPopup from './ClickToCallPopup';
import { PhoneIntegrationType } from 'src/handlers/handlePhoneCall';
import {
  addEnreachVoiceCallRequest,
  addMitelCallRequest,
  addOCCallRequest,
  addRingCallRequest
} from 'src/actions/phoneActions';
import type { Field } from 'src/types/Info';
import type { State } from 'src/types/initialState';
import type { PersonalData } from 'src/types/User';
import * as styles from './FieldHeader.style';

interface FieldHeaderProps {
  taskId?: string | number;
  taskType?: string;
  field: Field;
  fieldValue: any;
  isNegative?: boolean;
  personalData?: PersonalData;
  fireSearch?(fieldValue: any, value: Field['value']): void;
  children?: React.ReactNode;
  width?: SemanticWIDTHS;
}

const FieldHeader: FC<FieldHeaderProps> = ({
  children,
  taskId,
  taskType,
  field,
  fieldValue,
  personalData,
  isNegative = false,
  fireSearch,
  width = 6
}) => {
  const dispatch = useDispatch();
  const ticketTypes = useSelector((state: State) => state.ticketTypes);

  if (!field.name) {
    return null;
  }

  const showPopup = personalData?.permissions.includes('allowPhoneIntegrations') && field.customType === 'phoneNumber';

  return (
    <>
        <div style={styles.headerWrapper}>
          <Header as="h5" style={styles.header}>
            {field.name}
          </Header>
          <div style={styles.buttonWrapper}>
            {FeatureFlags.isFlagOn('ENABLE_CLICK_TO_SEARCH') &&
              field.params?.clickToSearch &&
              typeof fireSearch === 'function' && (
                <Button
                  style={styles.button}
                  icon="search"
                  onClick={() => {
                    if (fieldValue) {
                      fireSearch(fieldValue, field.value);
                    } else {
                      console.error('search value is empty');
                    }
                  }}
                />
              )}
            {showPopup && FeatureFlags.isFlagOn('ENABLE_OC_CLICK_TO_CALL') && (
              <ClickToCallPopup
                type={PhoneIntegrationType.ELISA_OC}
                onButtonClick={() => {
                  if (!!taskId && !!fieldValue) {
                    dispatch(addOCCallRequest(taskId, fieldValue, taskType, ticketTypes));
                  } else {
                    console.error('no ring call function present or search value empty');
                  }
                }}
              />
            )}
            {showPopup && FeatureFlags.isFlagOn('ENABLE_RING_CLICK_TO_CALL') && (
              <ClickToCallPopup
                type={PhoneIntegrationType.ELISA_RING}
                onButtonClick={() => {
                  if (!!taskId && !!fieldValue) {
                    dispatch(addRingCallRequest(taskId, fieldValue));
                  } else {
                    console.error('taskId or search value empty');
                  }
                }}
              />
            )}
            {showPopup && FeatureFlags.isFlagOn('ENABLE_MITEL_CLICK_TO_CALL') && (
              <ClickToCallPopup
                type={PhoneIntegrationType.MITEL}
                onButtonClick={() => {
                  if (!!taskId && !!fieldValue) {
                    dispatch(addMitelCallRequest(taskId, fieldValue));
                  } else {
                    console.error("Can't call mitel function");
                  }
                }}
              />
            )}
            {showPopup && FeatureFlags.isFlagOn('ENABLE_ENREACH_VOICE_CLICK_TO_CALL') && (
              <ClickToCallPopup
                type={PhoneIntegrationType.ENREACH_VOICE}
                onButtonClick={() => {
                  if (!!taskId && !!fieldValue) {
                    dispatch(addEnreachVoiceCallRequest(taskId, fieldValue));
                  } else {
                    console.error("Can't call enreach function");
                  }
                }}
              />
            )}

            {showPopup && FeatureFlags.isFlagOn('ENABLE_GENERIC_PHONE_LINK_CLICK_TO_CALL') && (
              <ClickToCallPopup
                type={PhoneIntegrationType.GENERIC_LINK}
                onButtonClick={() => {
                  if (!!taskId && !!fieldValue) {
                    document.location.href = `tel:${fieldValue}`;
                  } else {
                    console.error("Can't call generic phone function");
                  }
                }}
              />
            )}
          </div>
        </div>
      {children}
    </>
  );
};

export default FieldHeader;
