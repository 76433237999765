import { cloneDeep, set } from 'lodash-es';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import type { FC } from 'react';

import AccordionHeader from '../AccordionHeader';
import InfoField from './InfoField/InfoField';
import formatTitleWithValues from 'src/Components/Utilities/formatTitleWithValues';
import ErrorBoundary from 'src/ErrorBoundary';
import { FieldTypes } from 'src/types/Info';
import { processSubEntities } from 'src/Utilities/info';
import { normalizePhoneNumber } from 'src/Utilities/normalizeNumber';

import type { Field, OnInfoSave } from 'src/types/Info';
import type { FormattedSearch } from 'src/types/Search';

import './Info.css';

const MAX_VISIBLE_ACCORDION_ITEMS = 5;

interface InfoProps {
  fields: Field[];
  values: any;
  taskId?: string | number;
  datagroup?: string;
  taskType?: string;
  params?: any;
  entity?: any;
  language?: string;
  ringActive?: boolean;
  hideNoSearch?: boolean;
  striped?: boolean;
  generalDisable?: boolean;
  editDisable?: boolean;
  searchableFields?: boolean;
  disableInputIfNotMongoCustomer?: boolean;
  disableWarning?: boolean;

  onSave?: OnInfoSave;
  fireSearch?(...args: any[]): any;
  onChange?: (field: Field, value: any) => void;
}

const Info: FC<InfoProps> = ({
  fields,
  onSave,
  fireSearch,
  striped,
  onChange,
  taskId,
  datagroup,
  disableInputIfNotMongoCustomer,
  generalDisable,
  editDisable,
  taskType,
  params,
  entity,
  language,
  searchableFields,
  hideNoSearch,
  values: originalValues,
  disableWarning
}) => {
  const [values, setValues] = useState(originalValues);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  useEffect(() => {
    setValues(originalValues);
  }, [originalValues]);

  const getFieldSearchableStatus = (field: Field) => {
    return !!searchableFields && !!field.searchable;
  };

  const setValue = (field: Field, value: any) => {
    if (editDisable) {
      return;
    }

    const _values = { ...values };

    if (field.params?.dependentFields?.length) {
      for (const fieldName of field.params.dependentFields as string[]) {
        _values[fieldName] = undefined;
      }
    }

    if (typeof field.object !== 'undefined' && !Array.isArray(field.value) && typeof field.value !== 'boolean') {
      const newObjectValue = {
        ..._values[field.object.toString()]
      };

      set(newObjectValue, field.value, value);
      set(_values, field.object.toString(), newObjectValue);
    } else {
      set(_values, field.value.toString(), value);
    }

    setValues(_values);
    if (typeof onChange === 'function') onChange(field, value);
  };

  const onSaveData = (field: Field, value: any) => {
    let valueToSave = value;

    if (editDisable) {
      return;
    }

    if (typeof field.customType !== 'undefined') {
      switch (field.customType) {
        case 'phoneNumber':
          valueToSave = normalizePhoneNumber(valueToSave);
          if (valueToSave !== value) {
            setValue(field, valueToSave);
          }
          break;
        case 'datepicker':
          /**
           * Clean the date to invalid date if user write something wrong
           */
          if (moment(value).isValid() === false || !value) {
            setValue(field, '');
          }

          setValue(field, value);
          break;
        default:
          console.error(`No custom type function specified for customType ${field.customType}`);
      }
    }
    if (typeof onSave === 'function') {
      let optionText = '';
      if (typeof field.options !== 'undefined') {
        let option;
        if (Array.isArray(field.options)) {
          option = field.options.find((optionItem) => optionItem.value === valueToSave);
        }
        if (typeof option !== 'undefined') {
          optionText = option.name;
        }
      }
      const fieldName = field.value as keyof FormattedSearch['basic']; // TODO typing;
      const partial = typeof field.object !== 'undefined';
      const object = partial ? field.object : false;
      const [fieldNamePrefix] = fieldName.split('.');

      let fieldValues = values;
      if (fieldNamePrefix && values[fieldNamePrefix]?._id) {
        fieldValues = values[fieldNamePrefix];
      }

      onSave(
        fieldName,
        valueToSave,
        object,
        partial,
        field.name as string, // TODO typing
        optionText,
        datagroup,
        fieldValues._type,
        fieldValues._id,
        entity?.entityTypes
      );
    }
  };

  const onSaveDataSubAccordionField =
    (field: Field): OnInfoSave =>
    (param, valueToSave, ...args) => {
      const fieldName = `${field.name!}.${param}` as keyof FormattedSearch['basic'];
      const _values = { ...values };

      set(_values, fieldName, valueToSave);
      setValues(_values);

      if (typeof onSave === 'function') onSave(fieldName, valueToSave, ...args);
    };

  const filteredFields: Field[] = (entity?.[entity.id] ?? fields).filter(
    (field: Field) => !hideNoSearch || !field.params?.noSearch
  );
  const tableComponentFields: Field[] = processSubEntities(
    cloneDeep(filteredFields?.filter((f) => f.customType !== FieldTypes.subAccordion)),
    cloneDeep(values)
  );
  const getFieldValues = (subValues: any) => ({
    _id: values._id,
    _type: values._type,
    mongoCustomer: values.mongoCustomer,
    twoWayIntegrationEntity: values.twoWayIntegrationEntity,
    ...subValues
  });
  const additionalComponentsFields: Field[] = filteredFields?.filter((f) => f.customType === FieldTypes.subAccordion);

  return (
    <ErrorBoundary>
      {tableComponentFields?.length > 0 && (
        <>
          {tableComponentFields.map((field) => (
            <InfoField
              entity={entity}
              fields={tableComponentFields}
              field={field}
              values={values}
              isMobile={isMobile}
              params={params}
              taskId={taskId}
              taskType={taskType}
              language={language}
              generalDisable={generalDisable}
              disableInputIfNotMongoCustomer={disableInputIfNotMongoCustomer}
              fireSearch={fireSearch}
              onSaveData={onSaveData}
              setValue={setValue}
              fieldSearchableStatus={getFieldSearchableStatus(field)}
              disableWarning={disableWarning}
            />
          ))}
        </>
      )}
      {additionalComponentsFields.length > 0 && values[additionalComponentsFields[0].name!] && (
        <>
          {additionalComponentsFields.map((field) => (
            <div key={`info-additional-table-component-field-${field.name}`}>
              <AccordionHeader
                as="h4"
                active={true}
                title={formatTitleWithValues(field.displayName || field.title, field.value) || field.name || ''}
                icon="info circle"
              >
                {Array.isArray(values[field.name!]) ? (
                  values[field.name!].map((subAccValue: any, index: number) => {
                    const value = getFieldValues(subAccValue);
                    const maxVisibleAccordionItems =
                      field.params?.maxVisibleAccordionItems ?? MAX_VISIBLE_ACCORDION_ITEMS;
                    const childTitle =
                      formatTitleWithValues(field.childTitle, value) || `${field.childPrefix ?? ''} ${index + 1}`;

                    return (
                      <div key={`info-value-accordion-header-wrapper-${index}`}>
                        <AccordionHeader
                          key={`info-value-accordion-header-${index}`}
                          as="h4"
                          title={childTitle}
                          icon="info circle"
                          active={index < maxVisibleAccordionItems}
                        >
                          <Info
                            disableInputIfNotMongoCustomer
                            fields={field.value as any}
                            taskId={taskId}
                            taskType={taskType}
                            values={getFieldValues(subAccValue)}
                            onSave={onSaveDataSubAccordionField(field)}
                            disableWarning={disableWarning}
                          />
                        </AccordionHeader>
                      </div>
                    );
                  })
                ) : (
                  <Info
                    disableInputIfNotMongoCustomer
                    fields={field.value as any}
                    taskId={taskId}
                    taskType={taskType}
                    values={getFieldValues(values[field.name!])}
                    onSave={onSaveDataSubAccordionField(field)}
                    disableWarning={disableWarning}
                  />
                )}
              </AccordionHeader>
            </div>
          ))}
        </>
      )}
    </ErrorBoundary>
  );
};

export default React.memo(Info);
