import classNames from 'classnames';
import React from 'react';
import { Button as SemanticButton } from 'semantic-ui-react';

import type { CSSProperties } from 'react';
import type { ButtonProps as SemanticButtonProps } from 'semantic-ui-react';

import './Button.css';

type ButtonSize = 'sm' | 'md' | 'lg';
type ButtonType = 'primary' | 'normal' | 'error' | 'positive';

export interface ButtonProps {
  disabled?: boolean;
  content?: React.ReactNode;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  size?: ButtonSize;
  type?: ButtonType;
  style?: CSSProperties;
  icon?: React.ReactNode;
  center?: boolean;

  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: SemanticButtonProps) => void;
}

const Button = ({
  disabled,
  content,
  iconLeft,
  iconRight,
  size = 'md',
  type = 'normal',
  icon,
  center,
  style,
  onClick
}: ButtonProps) => {
  const buttonClass = classNames('eeedoButton', `size__${size}`, `type__${type}`, {
    iconOnly: !!icon,
    center: !!center
  });

  return (
    <SemanticButton style={style} className={buttonClass} disabled={disabled} onClick={onClick}>
      {iconLeft && <span className="icon-wrapper">{iconLeft}</span>}
      {content && <span className="content-wrapper">{content}</span>}
      {icon && <span className="icon-wrapper">{icon}</span>}
      {iconRight && <span className="icon-wrapper">{iconRight}</span>}
    </SemanticButton>
  );
};

export default Button;
