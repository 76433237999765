import { faFileLines, faFloppyDisk, faPencil, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import type { WithTranslation } from 'react-i18next';

import { updateTicket } from '../../actions/ticketsActions';
import FeatureFlags from '../../api/FeatureFlags';
import { contentsVC } from '../../api/VersionControl';
import { parseContent, serializeContent } from '../../Utilities/parseUtils';
import { sanitizeHTML } from '../../Utilities/sanitize';
import { taskIdToNumericalId } from '../../Utilities/ticketList';
import AIFunctionButtons from '../Comment/AIPromptButton';
import Button from '../generic/Button/Button';
import { VersionCounters, VersionViewer } from '../Versions';

import type { State } from '../../types/initialState';
import type { Attachment, Ticket } from '../../types/Ticket';
import type { ViewMode } from '../Versions/VersionViewer';

const ReplyEditor = React.lazy(() => import('./ReplyEditor'));

const AUTOSAVE_TIMEOUT = 1000 * 60 * 15;

interface CaseContentEditorState {
  content: string;
  isEditing: boolean;
  versionViewMode: ViewMode;
}

type Props = StateProps & DispatchProps & WithTranslation;

interface StateProps {
  id: string;
  content: string;
  taskAttachments: Attachment[];
  ticket: Ticket;
}

interface DispatchProps {
  update(ticketId: string, content: string): void;
}

const mapStateToProps = (state: State): StateProps => {
  // TODO typing nullable
  const ticket = state.detailedTickets.find((ticket) => ticket.id === state.activeTicketTab)!;
  return {
    id: ticket?.id,
    content: ticket?.content,
    taskAttachments: ticket.attachments,
    ticket: ticket
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    update: (id: string, content: string) => {
      dispatch(updateTicket(id, { content }));
    }
  };
};

class CaseContentEditor extends React.Component<Props, CaseContentEditorState> {
  private autoSaveIntervalId?: NodeJS.Timeout;

  constructor(props: Props) {
    super(props);
    this.state = {
      content: props.content,
      isEditing: false,
      versionViewMode: null
    };
  }

  private setEditing(editing: boolean, newContent?: string) {
    this.setState((old) => {
      newContent = newContent ?? old.content;

      if (editing && old.isEditing !== editing) {
        newContent = parseContent(newContent, this.props.taskAttachments, true);
      }

      return { isEditing: editing, content: newContent };
    });
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!this.state.isEditing && this.props.content !== nextProps.content) {
      this.setState({
        content: nextProps.content
      });
    }
  }

  componentWillUnmount() {
    if (this.state.isEditing && this.props.content !== this.state.content) {
      const id = taskIdToNumericalId(this.props.id);
      contentsVC.sendDraft({
        id,
        content: this.state.content,
        attachments: this.props.taskAttachments,
        forceSave: true
      });
    }

    this.toggleBeforeUnloadEvent(false);
    clearInterval(this.autoSaveIntervalId);
  }

  componentDidUpdate(prevProps: Props, prevState: CaseContentEditorState) {
    if (
      this.state.isEditing &&
      this.props.content !== this.state.content &&
      prevProps.ticket.id === this.props.ticket.id
    ) {
      const id = taskIdToNumericalId(this.props.id);
      contentsVC.sendDraft({
        id,
        content: this.state.content,
        attachments: this.props.taskAttachments
      });
    }

    if (this.state.isEditing !== prevState.isEditing) {
      this.toggleBeforeUnloadEvent(this.state.isEditing);

      if (this.state.isEditing) {
        this.autoSaveIntervalId = setInterval(this.autoSave, AUTOSAVE_TIMEOUT);
      } else {
        clearInterval(this.autoSaveIntervalId);
      }
    }
  }

  private toggleBeforeUnloadEvent = (enable: boolean) => {
    if (process.env.NODE_ENV !== 'development') {
      enable
        ? window.addEventListener('beforeunload', this.handleLeavePage)
        : window.removeEventListener('beforeunload', this.handleLeavePage);
    }
  };

  private autoSave = () => {
    if (this.props.content !== this.state.content) {
      const id = taskIdToNumericalId(this.props.id);
      contentsVC.sendDraft({
        id,
        content: this.state.content,
        attachments: this.props.taskAttachments,
        forceSave: true
      });
    }
  };

  private handleLeavePage(e: BeforeUnloadEvent) {
    const confirmationMessage = 'Are you sure?';
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  }

  private startEdit = async () => {
    const id = taskIdToNumericalId(this.props.id);
    const draft = await contentsVC.loadDraft({
      taskId: id,
      attachments: this.props.taskAttachments
    });
    this.setEditing(true, draft?.content);
  };

  private saveDraft = () => {
    const id = taskIdToNumericalId(this.props.id);
    this.setState({
      isEditing: false,
      content: this.props.content
    });
    contentsVC.sendDraft({
      id,
      content: this.state.content,
      attachments: this.props.taskAttachments,
      forceSave: true
    });
  };

  private cancelEdit = () => {
    this.setState({
      isEditing: false,
      content: this.props.content
    });
  };

  private save = () => {
    if (this.state.content === this.props.content) {
      return;
    }

    this.setState({ isEditing: false }, () => {
      contentsVC.clearEntry(taskIdToNumericalId(this.props.id));
      const content = serializeContent(this.state.content, this.props.taskAttachments);
      this.props.update(this.props.id, content);
    });
  };

  // version and draft load
  private onDraftCounterClick = (name: ViewMode) => {
    this.setState({ versionViewMode: name });
  };

  private onVersionViewClose = () => {
    this.setState({ versionViewMode: null });
  };

  // revert previous draft or version
  private onClickVersionLoad = (mode: ViewMode, id: number) => {
    this.setState({ versionViewMode: null });
    if (mode === 'drafts') {
      contentsVC.loadDraftById(taskIdToNumericalId(this.props.id), id).then((draft) => {
        if (draft) {
          this.setEditing(true, draft.content);
        }
      });
    }
    if (mode === 'versions') {
      contentsVC.loadVersionById(taskIdToNumericalId(this.props.id), id).then((version) => {
        if (version) {
          this.setEditing(true, version.previousContent);
        }
      });
    }
  };

  // TODO change this to be a hook component completely
  CaseContentEditorComponent = () => {
    const content = useMemo(() => {
      if (this.state.isEditing) {
        return this.state.content;
      }

      let metaData = this.props.ticket.metaData;
      if (typeof metaData === 'string') {
        try {
          metaData = JSON.parse(metaData);
        } catch (error) {
          // metaData is invalid -> not taking it into consideration
          metaData = null;
          console.error('Failed to parse metadata');
        }
      }
      // const html = metaData?.['html'];
      /**
       * Commented out functionality
       *  Q: Why? A: due to Business reasons and confusion around TSK20792 at later date.
       *  Q: When can this be removed? A: When business decides what we want to parse.
       */
      // const content = html ?? this.state.content;
      const content = this.state.content;
      // const nToBr = !html;
      const nToBr = true;

      return sanitizeHTML(parseContent(content, this.props.taskAttachments, nToBr));
    }, [this.state.isEditing, this.state.content, this.props.taskAttachments]);

    const idNum = taskIdToNumericalId(this.props.id);
    const { t } = this.props;

    return (
      <React.Fragment key={`cce-${this.props.id}`}>
        <VersionViewer
          id={idNum}
          dataSource={contentsVC}
          mode={this.state.versionViewMode}
          onClose={this.onVersionViewClose}
          onClickLoad={this.onClickVersionLoad}
        />
        <VersionCounters id={idNum} dataSource={contentsVC} onClick={this.onDraftCounterClick} />
        {this.state.isEditing && (
          <React.Fragment>
            <div className="decriptionEditContainer">
              <React.Suspense fallback={<div>{t('LOADING')}...</div>}>
                <ReplyEditor
                  style={{
                    borderRadius: '5px',
                    marginTop: '10px',
                    marginBottom: '10px'
                  }}
                  value={content}
                  onChange={(value: string) =>
                    this.setState({
                      content: value
                    })
                  }
                  onKeyDown={(event) => {
                    if (event.keyCode === 13 && (event.ctrlKey || event.metaKey)) {
                      this.save();
                    }
                  }}
                  ticket={this.props.ticket}
                />
              </React.Suspense>

              <div className="case-content-editor__buttons">
                <Button
                  content={t('GENERAL_CANCEL')}
                  iconLeft={<FontAwesomeIcon icon={faXmark} />}
                  // id="descriptionFieldEditCancelBtn"
                  onClick={this.cancelEdit}
                />
                <Button
                  content={t('GENERAL_SAVE')}
                  disabled={this.state.content === this.props.content}
                  iconLeft={<FontAwesomeIcon icon={faFloppyDisk} />}
                  onClick={this.save}
                  type="primary"
                />

                <Button
                  content={t('GENERAL_SAVE_DRAFT')}
                  iconLeft={<FontAwesomeIcon icon={faFileLines} />}
                  // id="descriptionFieldEditSaveDraftBtn"
                  onClick={this.saveDraft}
                />
              </div>
            </div>
          </React.Fragment>
        )}
        {!this.state.isEditing && (
          <React.Fragment>
            <div
              className="descriptionField images-max-w-95"
              style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}
              dangerouslySetInnerHTML={{
                __html: content
              }}
            />
            <div className="case-content-editor__buttons">
              {FeatureFlags.isFlagOn('ENABLE_OPENAI') && <AIFunctionButtons />}

              <Button
                // id="descriptionFieldEditBtn"
                content={t('GENERAL_EDIT')}
                iconLeft={<FontAwesomeIcon icon={faPencil} />}
                onClick={this.startEdit}
              />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  render() {
    return <this.CaseContentEditorComponent />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CaseContentEditor));
