import { get } from 'lodash-es';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Message } from 'semantic-ui-react';

import type { ChangeEvent, CSSProperties, FC, KeyboardEvent } from 'react';

import type { InfoFieldProps } from './InfoFieldProps';
import type { Field } from 'src/types/Info';
import Input from 'src/Components/generic/Input/Input';

interface InfoInputProps extends Pick<InfoFieldProps, 'onSaveData' | 'setValue'> {
  field: Field;
  fieldValue: any;
  values?: { [k: string]: any };
  inputType: 'text' | 'number';
  disabled: boolean;
  mongoSaveDisabled: boolean;
  integrationSaveDisabled: boolean;
  id?: string;
  name?: string;
  className?: string;
  style?: CSSProperties;
  disableWarning?: boolean;
}

const InfoInput: FC<InfoInputProps> = ({
  field,
  inputType,
  fieldValue,
  values,
  mongoSaveDisabled,
  integrationSaveDisabled,
  id,
  name,
  disabled,
  className,
  setValue,
  onSaveData,
  disableWarning
}) => {
  const { t } = useTranslation();
  const [showWarning, setShowWarning] = useState(false);
  const { regex, flags, errorText } = field.validation || {};
  const error = regex ? !new RegExp(regex, flags).test(fieldValue) : false;
  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && !integrationSaveDisabled && !mongoSaveDisabled && !error) {
        onSaveData?.(field, e.currentTarget.value);
      }
    },
    [integrationSaveDisabled, mongoSaveDisabled, onSaveData, field, error]
  );
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setShowWarning(false);
      if (!integrationSaveDisabled && !mongoSaveDisabled) {
        setValue?.(field, e.target.value);
      }
    },
    [integrationSaveDisabled, mongoSaveDisabled, setValue, field]
  );
  const onFocus = useCallback(() => {
    if (field.value === 'email' || field.value === 'phone') {
      setShowWarning(true);
    }
  }, []);
  const onBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setShowWarning(false);
      if (!integrationSaveDisabled && !mongoSaveDisabled && !error) {
        onSaveData?.(field, e.target.value);
      }
    },
    [integrationSaveDisabled, mongoSaveDisabled, onSaveData, field, error]
  );

  return (
    <>
      <Input
        id={id}
        label={name}
        name={name}
        type={inputType}
        disabled={disabled}
        className={className}
        error={error}
        size="mini"
        onKeyDown={onKeyDown}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={fieldValue || ''}
        fluid={true}
        {...(field.params?.unit && {
          labelPosition: 'right',
          label: get(values, field.params.unit, '')
        })}
      />
      {error && <Message error content={errorText} />}
      {!disableWarning && showWarning && <Message warning content={t('widgets.info_field.warning')} />}
    </>
  );
};

export default React.memo(InfoInput);
