import React from 'react';
import { Icon, Input as SInput } from 'semantic-ui-react';

import type { FC } from 'react';
import type { InputOnChangeData, SemanticICONS, InputProps as SemanticInputProps } from 'semantic-ui-react';

import './Input.css';

import classNames from 'classnames';

interface InputProps extends SemanticInputProps {
  value: string;
  label?: string;
  hint?: string;
  errorMessage?: string;
  placeholder?: string;
  icon?: SemanticICONS;
  disabled?: boolean;

  onChange: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
}

const Input: FC<InputProps> = ({
  value,
  icon,
  label,
  hint,
  placeholder,
  disabled,
  errorMessage,
  onChange,
  ...rest
}) => {
  const wrapperClasses = classNames({ 'input-wrapper': true, error: !!errorMessage });

  return (
    <div className={wrapperClasses}>
      {label && <label>{label}</label>}
      <SInput
        iconPosition={icon ? 'left' : undefined}
        icon={icon}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        {...rest}
      />
      {(hint || errorMessage) && (
        <div className="hint">
          <Icon name="info circle" /> {hint ?? errorMessage}
        </div>
      )}
    </div>
  );
};

export default React.memo(Input);
