import React from 'react';

import type { FC } from 'react';
import type { SemanticICONS } from 'semantic-ui-react';

import Dropdown from 'src/Components/generic/Dropdown/Dropdown';

import type { StatusTypes } from 'src/types/Ticket';

interface TicketStatusDropdownProps {
  disabled: boolean;
  value: string;
  loading: boolean;
  statusOptions: {
    text: string;
    icon: SemanticICONS;
    value: string;
  }[];
  handleStatusChange: (value: string) => void;
}

const TicketStatusDropdown: FC<TicketStatusDropdownProps> = ({
  disabled,
  value,
  loading,
  statusOptions,
  handleStatusChange
}) => {
  return (
    <Dropdown
      disabled={disabled}
      selectOnBlur={false}
      value={value}
      loading={loading}
      id="ticketStatusDropdown"
      onChange={(event, data) => {
        handleStatusChange(data.value!.toString() as StatusTypes);
      }}
      options={statusOptions}
      selection
      search
    />
  );
};

export default TicketStatusDropdown;
