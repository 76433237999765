import { faSlidersSimple } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { goBack, push } from 'connected-react-router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Button, Icon, Menu, Tab } from 'semantic-ui-react';

import type { FC } from 'react';
import type { ConnectedProps } from 'react-redux';
import type { TabProps } from 'semantic-ui-react';

import { FilterPresetView } from '../Management/FilterPresets/types';
import Search from './Search';
import TicketLists from './TicketLists';
// import OpenById from './OpenById';
import { fetchFilterPresets, resetFilterPresets, setActiveFilterPreset } from 'src/actions/filterPresetsActions';
import { setInfopagelistFilter } from 'src/actions/infoPagesActionsRTK';
import { setTicketlistFilter, setTicketlistSorting } from 'src/actions/ticketListTabActionsRTK';
import FeatureFlags from 'src/api/FeatureFlags';
import TicketListFilter from 'src/containers/TicketListFilterContainer';
import ErrorBoundary from 'src/ErrorBoundary';
import { DefaultTicketsOrdering } from 'src/types/Sorting';
import { SINGLE_LINE_VIEW_PATH } from 'src/Utilities/constants';

import type { IFilterItem } from 'src/types/Filter';
import type { State } from 'src/types/initialState';
import type { MenuTab } from 'src/types/MenuTab';
import type { ContentTypesFields } from 'src/types/Ticket';
import type { TicketListTab as TicketTab } from 'src/types/TicketList';

const [defaultDirection, defaultSorting] = DefaultTicketsOrdering;

interface OwnProps {
  contentType: ContentTypesFields;
  noDefaultDates?: boolean;
}

interface Props extends TicketListTabReduxProps, OwnProps {}

const TicketListTab: FC<Props> = (props) => {
  const [showLegacyMenu] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(2);

  const { pathname } = props;
  const isSingleLineView = pathname === SINGLE_LINE_VIEW_PATH;

  // Check undefined values in filters objects
  const isFilterOn =
    !Object.keys(props.filters).every((k) => !props.filters[k]) ||
    (props.sorting && props.sorting !== defaultSorting) ||
    (props.direction && props.direction !== defaultDirection);

  useEffect(() => {
    if (isSingleLineView) {
      setActiveIndex(0);
    }
  }, [pathname]);

  useEffect(() => {
    const { pathname, contentType } = props;
    // this component never seems to unmount so need to follow some state change
    if (pathname === '/' || ['/case', '/infopage'].some((path) => pathname.startsWith(path))) {
      dispatch(
        fetchFilterPresets({
          view: contentType === 'infopages' ? FilterPresetView.infopage : FilterPresetView.main
        })
      );
    }
  }, [props.pathname, props.contentType]);

  useEffect(() => {
    return () => {
      props.resetFilterPresets();
    };
  }, []);

  useEffect(() => {
    if (isFilterOn) {
      return;
    }

    const defaultFilter = props.filterPresets.find((p) => p.id === props.defaultFilterPreset);
    if (defaultFilter) {
      if (!props.activeTabId) {
        return;
      }

      dispatch(setActiveFilterPreset({ filterPresetId: defaultFilter.id }));

      Object.keys(defaultFilter?.filters).forEach((filterKey) => {
        props.setFilter({
          id: props.activeTabId!,
          value: defaultFilter?.filters[filterKey],
          parameter: filterKey as any
        });
      });

      props.setSorting?.(defaultFilter.sorting as any, defaultFilter.direction);
    }
  }, [props.filterPresets, props.defaultFilterPreset]);

  const panes = useMemo(() => {
    return [
      {
        menuItem: FeatureFlags.isFlagOn('ENABLE_TICKET_LIST_FILTERING') ? (
          <Menu.Item
            key="filterComponentTab"
            id="filterComponentTab"
            className="filterComponentTab"
            style={{ marginLeft: '10px !important' }}
          >
            <Icon name="filter" style={{ marginRight: '5px' }} color={isFilterOn ? 'red' : undefined} />
            <span
              style={{
                textDecoration: isFilterOn ? 'underline' : 'none',
                color: isFilterOn ? 'red' : 'inherit'
              }}
            >
              {t('SEARCH_FIlTER_OPTIONS')}
            </span>
          </Menu.Item>
        ) : undefined,
        render: () =>
          FeatureFlags.isFlagOn('ENABLE_TICKET_LIST_FILTERING') ? (
            <ErrorBoundary>
              <TicketListFilter contentType={props.contentType} />
            </ErrorBoundary>
          ) : undefined
      },
      {
        menuItem: (
          <Menu.Item key="searchComponentTab" id="searchComponentTab" className="filterComponentTab">
            <Icon name="search" />
            {t('SEARCH_MORE_SEARCH_OPTIONS')}
          </Menu.Item>
        ),
        render: () => (
          <ErrorBoundary>
            <Search
              noDefaultDates={props.noDefaultDates}
              contentType={props.contentType}
              closePane={() => setActiveIndex(2)}
            />
          </ErrorBoundary>
        )
      }
      // {
      //   menuItem: (
      //     <Menu.Item key="searchByIdTab" className="filterComponentTabFloatRight NoActiveTabEffects">
      //       <OpenById contentType={props.contentType} />
      //     </Menu.Item>
      //   )
      // }
    ];
  }, [props.noDefaultDates, props.contentType, t, isFilterOn]);

  const changeActiveIndex = useCallback(
    (_event: any, tabData: TabProps) => {
      const index = tabData.activeIndex;

      if (activeIndex !== index) {
        setActiveIndex(Number(index));
      } else if (!isSingleLineView) {
        setActiveIndex(2);
      }
    },
    [activeIndex]
  );

  return (
    <React.Fragment>
      <div style={{ paddingBottom: '10px', height: '45px', display: 'flex', justifyContent: 'space-between' }}>
        {showLegacyMenu && (
          <Tab className="searchTabMenu" panes={panes} activeIndex={activeIndex} onTabChange={changeActiveIndex} />
        )}
        <div
          style={{
            paddingTop: '10px',
            borderBottom: '1px solid #eeeeee',
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
            marginLeft: '10px',
            marginRight: '10px',
            paddingBottom: '10px'
          }}
        >
          <div>
            <FontAwesomeIcon
              style={{ marginRight: '5px' }}
              color={isFilterOn ? 'red' : undefined}
              icon={faSlidersSimple}
            />
            {t('SEARCH_FIlTER_OPTIONS')}
          </div>
          <div>
            <Icon name="search" />
            {t('SEARCH_MORE_SEARCH_OPTIONS')}
          </div>
        </div>
        <div style={{ flexGrow: '1', width: '40%' }}>
          <Button
            size="mini"
            style={{ marginTop: '5px', marginLeft: '25px' }}
            basic
            onClick={() => {
              const action = isSingleLineView ? goBack() : push(SINGLE_LINE_VIEW_PATH);
              dispatch(action);
            }}
          >
            {isSingleLineView ? 'List' : 'Line'}
          </Button>
        </div>
      </div>
      {!isSingleLineView && (
        <ErrorBoundary>
          <TicketLists contentType={props.contentType} />
        </ErrorBoundary>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const tabs: MenuTab[] =
    ownProps.contentType === 'tickets' ? Object.values(state.ticketListTabs) : [...state.infoPageListTabs.values()];
  const activeTab = tabs.find((tab) => tab.activeTab);

  return {
    activeTabId: activeTab?.id,
    filters: activeTab?.filters ?? {},
    sorting: (activeTab as TicketTab)?.sorting,
    direction: (activeTab as TicketTab)?.direction,
    filterPresets: state.filterPresets.filterPresets,
    defaultFilterPreset: state.userData.userPreferences.defaultFilterPreset,
    pathname: state.router.location.pathname
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => ({
  setFilter: (item: IFilterItem) => {
    dispatch((ownProps.contentType === 'infopages' ? setInfopagelistFilter : setTicketlistFilter)(item));
  },
  setSorting:
    ownProps.contentType === 'tickets'
      ? (...args: Parameters<typeof setTicketlistSorting>) => {
          dispatch(setTicketlistSorting(...args));
        }
      : undefined,
  resetFilterPresets: () => {
    dispatch(resetFilterPresets());
  }
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type TicketListTabReduxProps = ConnectedProps<typeof connector>;

export default connector(TicketListTab);
