import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect } from 'react-redux';

import type { FC } from 'react';
import type { ConnectedProps } from 'react-redux';

import UserAvatar from '../Components/User/UserAvatar';
import UserPopup from '../Components/User/UserPopup';
import { updateTicket } from 'src/actions/ticketsActions';
import FeatureFlags from 'src/api/FeatureFlags';
import TicketsApi from 'src/api/TicketsApi';
import Button from 'src/Components/generic/Button/Button';
import { handleAssignOperationRejection } from 'src/Utilities/ticket';
import { startWorkingOn } from 'src/Utilities/workStatusParser';

import type { State } from 'src/types/initialState';
import type { PersonalData, User } from 'src/types/User';

export interface OwnProps {
  id: string;
  showStartWorkingOnButton: boolean;
  user?: User;
  userData?: PersonalData;
}

type Props = ConnectedProps<typeof connector>;

const WorkStatusImage: FC<Props> = ({
  id,
  userData,
  usersList,
  workStatus,
  currentUserData,
  showStartWorkingOnButton,
  startWorkingOn
}) => {
  const currentStatus = workStatus.status.find((status) => 'TSK' + status.ticketId === id);
  const currentUser =
    typeof currentStatus !== 'undefined' ? usersList.find((user) => user.UID === 'USR' + currentStatus.UID) : null;
  const showButton = showStartWorkingOnButton && FeatureFlags.isFlagOn('ENABLE_START_WORKING_ON_FROM_TICKETLIST');

  if (!currentUser && showButton) {
    return (
      <Button
        disabled={!userData?.permissions.includes('updateContent')}
        onClick={() => {
          startWorkingOn(currentUserData.UID, id);
        }}
        type="normal"
        size="sm"
        icon={<FontAwesomeIcon icon={faPlus} />}
        style={{ marginTop: 'auto', marginRight: 0, maxHeight: '36px' }}
      />
    );
  }

  if (!currentUser) {
    return null;
  }

  return (
    <div className="ticket-current-agent topBarTicketAgent">
      <UserPopup UID={currentUser.UID}>
        <span>
          <UserAvatar user={currentUser} size="26" round />
        </span>
      </UserPopup>
    </div>
  );
};

const connector = connect(
  (state: State, ownProps: OwnProps) => ({
    ...ownProps,
    workStatus: state.workStatus,
    usersList: state.usersList.usersList,
    currentUserData: state.userData,
    id: ownProps.id
  }),
  (dispatch: any) => ({
    startWorkingOn: (UID: string, taskId: string) => {
      TicketsApi.startWorkingOn(...startWorkingOn(UID, taskId))
        .then(() => dispatch(updateTicket(taskId, { status: 'doing' })))
        .catch(handleAssignOperationRejection);
    }
  })
);

export default connector(WorkStatusImage);
