import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import type { ReactNode } from 'react';

import { LayoutLeftMenu } from '../LayoutLeftMenu';
import { CommandPalette } from './CommandPalette';
import { DesktopLayoutRight } from './DesktopLayoutRight';
import { DesktopLayoutWidgetMenu } from './DesktopLayoutWidgetMenu';
import { ListOfPresetFilters } from './ListOfPresetFilters';
import UnifiedMenuAndFilters from './UnifiedMenuAndFilters';
import FeatureFlags from 'src/api/FeatureFlags';
import PhoneButtonsFloating from 'src/Components/Case/PhoneButtonsFloating';
import AiAssistantButton from 'src/Components/Case/TopBar/AiAssistantButton';
import { FocusModeProvider } from 'src/Components/FocusMode/FocusModeContext';
import ActivateFeatureModal from 'src/Components/generic/ActivateFeatureModal';
import EntityViewer from 'src/Components/Management/Entities/EntityViewer';
import TicketsSidebar from 'src/Components/Sidebar/TicketsSidebar';
import SingleLineTicketView from 'src/Components/ticketList/SingleLineTicketView/SingleLineTicketView';
import CaseContainer from 'src/containers/CaseContainer';
import InfopageCaseContainer from 'src/containers/InfopageCaseContainer';
import SettingsContainer from 'src/containers/SettingsContainer';
import urlActionContainer from 'src/containers/urlActionContainer';
import ProfileDropdown from 'src/ProfileDropdown';
import ReportingPage from 'src/ReportingPage';
import { SINGLE_LINE_VIEW_PATH } from 'src/Utilities/constants';

import type { State } from 'src/types/initialState';

import './DesktopLayout.css';

import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useWidgetView, WidgetViewProvider } from 'src/Components/Case/Widget/WidgetViewContext';
import Button from 'src/Components/generic/Button/Button';

const _App = (props: any) => (
  <FocusModeProvider>
    <div className="layout-desktop">{props.children}</div>
  </FocusModeProvider>
);

const _LayoutTopBar = () => {
  const { toggleWidgetView } = useWidgetView();
  return (
    <div className="layout-desktop-topmenubar">
      <div className="layout-desktop-topmenubar-logo">
        <h1>Eeedo Desk 2024</h1>
      </div>
      <div className="layout-desktop-topmenubar-search">
        <CommandPalette />
      </div>
      <div className="layout-desktop-topmenubar-profile">
        <Button onClick={() => toggleWidgetView()} size="lg" icon={<FontAwesomeIcon icon={faLayerGroup} />} />
        <ProfileDropdown />
      </div>
    </div>
  );
};

const _LayoutColumns = (props: any) => (
  <div className="layout-desktop-columns">
    {props.children.map((child: ReactNode) => (
      <>{child}</>
    ))}
  </div>
);
const _LayoutSideMenu = () => <LayoutLeftMenu />;

const _LayoutFilters = () => <ListOfPresetFilters />;

const _LayoutTicketList = () => {
  const isSettingsPage = useSelector<State>((state) => state.router.location?.pathname.includes('/settings'));

  return !isSettingsPage ? (
    <div className="layout-desktop-ticketlist">
      <TicketsSidebar />
    </div>
  ) : null;
};
// TODO
const mobileMode = false;

const _LayoutContents = () => (
  <div className="layout-desktop-contents">
    <Route
      path="/case/:id"
      exact={true}
      render={(routeProps) => <CaseContainer {...routeProps} key={routeProps.match.params.id} />}
    />
    <Route path={SINGLE_LINE_VIEW_PATH} exact render={(routeProps) => <SingleLineTicketView {...routeProps} />} />
    <Route path="/reporting" component={ReportingPage} exact={true} />
    <Route
      path={['/settings/:pageName', '/settings']}
      render={(...props) => <SettingsContainer noMenuContainer={false} {...(props as any)} />}
    />
    {FeatureFlags.isFlagOn('ENABLE_ENTITY_VIEWER') && <Route path="/entities/" component={EntityViewer} />}
    {!mobileMode && FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE') && (
      <Route
        path="/infopage/:id"
        render={(routeProps) => <InfopageCaseContainer {...routeProps} key={routeProps.match.params.id} />}
      />
    )}
    {!FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE') && <Route path="/feature" component={ActivateFeatureModal} />}

    <Route path="/actions/:type" component={urlActionContainer} />
  </div>
);

const _LayoutFloating = () => <PhoneButtonsFloating />;

const _LayoutRightMenu = () => {
  const isSettingsPage = useSelector<State>((state) => state.router.location?.pathname.includes('/settings'));
  const isActiveTask = useSelector<State>(
    (state) => !!state.activeTicketTab && state.router.location?.pathname !== '/'
  );

  return !isSettingsPage && isActiveTask ? (
    <div className="layout-desktop-right-side-menu">
      <DesktopLayoutRight />
    </div>
  ) : null;
};

const _LayoutWidgetMenu = () => <DesktopLayoutWidgetMenu />;

const _UnifiedMenuAndFilters = () => <UnifiedMenuAndFilters />;

// const initialLayout = {
//   leftMenu: {
//     isMinimised: true
//   },
//   filters: {
//     isMinimised: true
//   },
//   lists: {
//     isMinimised: false
//   },
//   contents: {
//     isMinimised: false
//   },
//   rightMenu: {
//     isMinised: false
//   }
// };

const DesktopLayout = () => {
  const [isUnified, setIsUnified] = useState(true);
  // const [layout, setLayout] = useState(initialLayout);
  return (
    <_App>
      <WidgetViewProvider>
        <button onClick={() => setIsUnified(!isUnified)} style={{ display: 'none' }}>
          Switch between Unified menu and Menu + Filter presets separately
        </button>
        <_LayoutTopBar />
        <_LayoutColumns>
          {isUnified && <_UnifiedMenuAndFilters />}
          {!isUnified && <_LayoutSideMenu />}
          {!isUnified && <_LayoutFilters />}
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginRight: '20px',
              backgroundColor: 'white',
              borderRadius: '25px'
            }}
          >
            <_LayoutTicketList />
            <_LayoutContents />
            <_LayoutRightMenu />
          </div>
          <_LayoutWidgetMenu />
        </_LayoutColumns>

        {FeatureFlags.isFlagOn('ENABLE_AI_ASSISTANT') && <AiAssistantButton />}
        <_LayoutFloating />
      </WidgetViewProvider>
    </_App>
  );
};

// return (
//   <div className='wrapper'>
//     <Grid
//       verticalAlign="middle"
//       className="main-topbar"
//       style={{
//         ...(!!getUrlSearchParam('hideNavigation') && { display: 'none' }),
//       }}
//     >
//       <Grid.Row>
//         <Grid.Column floated="left" verticalAlign="middle">
//           <LeftSideMenuDropdown />
//         </Grid.Column>
//         <Grid.Column width={3} floated="left" textAlign="left">
//           <div className="logo" />
//         </Grid.Column>
//         <Grid.Column computer={8} tablet={4} mobile={1} />
//         <Grid.Column width={4} floated="right" textAlign="right">
//           <Grid>
//             <Grid.Row columns={2}>
//               <Grid.Column width={12} verticalAlign="middle" style={{ color: 'white' }} />
//                 <Grid.Column width={4}>
//                   <RightSideProfileDropdown />
//                 </Grid.Column>
//             </Grid.Row>
//           </Grid>
//         </Grid.Column>
//       </Grid.Row>
//     </Grid>
//     <ErrorBoundary>
//       <div className='main'>
//         {/* {props.children} */}
//       </div>
//     </ErrorBoundary>
//   </div>
// )

export default DesktopLayout;
