import iziToast from 'izitoast';
import { mergeLeft } from 'ramda';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dimmer, Icon, Loader, Message, Modal, Popup, Table } from 'semantic-ui-react';

import type { FC } from 'react';

import { updateEntityDetails } from 'src/actions/ticketsActions';
import EntityApi from 'src/api/EntityApi';
import InfoField from 'src/Components/Case/Info/InfoField/InfoField';
import { parseFieldValue } from 'src/Components/Utilities/infoField';
import { FieldTypes } from 'src/types/Info';
import { getEntityFieldsByEntityType } from 'src/Utilities/ticketTypes';

import type { EntitySearchResult } from 'src/types/EntityViewer';
import type { Field } from 'src/types/Info';
import type { State } from 'src/types/initialState';
import type { Entity } from 'src/types/Ticket';

interface EntityMergeModalProps {
  open: boolean;
  entities: Entity[];
  onClose(): void;
  onSuccess(updatedEntityId: EntitySearchResult): void;
}

const EntityMergeModal: FC<EntityMergeModalProps> = ({ open, entities, onClose, onSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fields = useSelector<State, Field[]>((state) =>
    entities.length
      ? getEntityFieldsByEntityType(state.ticketTypes, entities[0]._type).filter(
          (field) => field.value !== '_id' && field.customType !== FieldTypes.link
        )
      : []
  );
  const [mainEntity, entityToMerge] = entities;
  const mergedEntity = mergeLeft(mainEntity, entityToMerge);
  const fieldsAndValues = fields
    .map((field) => [
      field,
      parseFieldValue({ field, values: mainEntity, shouldEncodeUriComponent: false }),
      parseFieldValue({
        field,
        values: entityToMerge,
        shouldEncodeUriComponent: false
      })
    ])
    .filter(([, mainFieldValue, mergeFieldValue]) => mainFieldValue || mergeFieldValue) as [Field, string, string][];

  const [update, setUpdate] = useState<[Field, string][]>(
    fieldsAndValues
      .filter(([, mainFieldValue, mergeFieldValue]) => !mainFieldValue && mergeFieldValue)
      .map(([field, , mergeFieldValue]) => [field, mergeFieldValue])
  );
  const [isLoading, setIsLoading] = useState(false);

  const mergeEntities = useCallback(async () => {
    const [entityToUpdate, entityToDelete] = entities;
    setIsLoading(true);

    try {
      await EntityApi.mergeEntities({
        targetEntityId: entityToUpdate._id,
        mergedEntityId: entityToDelete._id,
        entityType: entityToUpdate._type
      });

      for (const [field, value] of update) {
        await dispatch(
          updateEntityDetails({
            updateArgs: {
              entityId: entityToUpdate._id,
              entityType: entityToUpdate._type,
              fieldName: field.value,
              object: false as any,
              partial: false,
              valueToSave: value
            }
          })
        );
      }

      iziToast.success({
        message: t('entityViewer.merge_success'),
        icon: 'icon check'
      });

      onClose();
      onSuccess(entityToUpdate);
    } catch (error) {
      console.error('Failed to merge entities', error);
      iziToast.error({
        message: t('entityViewer.merge_failed'),
        timeout: 3000,
        position: 'bottomRight'
      });
    } finally {
      setIsLoading(false);
    }
  }, [entities, update]);

  const isDifferentType = entities[0]?._type !== entities[1]?._type;

  return (
    <Modal open={open} onClose={onClose} closeIcon={!isLoading} closeOnDimmerClick={!isLoading}>
      <Dimmer active={isLoading || isDifferentType} inverted>
        {isLoading && <Loader inverted content={t('LOADING')} />}
        {isDifferentType && (
          <Message negative>
            <Message.Header>{t('entityViewer.merge_modal_different_types_heading')}:</Message.Header>
            <p style={{ color: 'rgb(145, 45, 43)' }}>{t('entityViewer.merge_modal_different_types_message')}</p>
          </Message>
        )}
      </Dimmer>
      <Modal.Header>{t('entityViewer.merge_modal_title')}</Modal.Header>
      <Modal.Content>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={'2'}>{t('entityViewer.merge_modal_result_values')}</Table.HeaderCell>
              <Table.HeaderCell>{t('entityViewer.merge_modal_conflicting_values')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {fieldsAndValues.map(([field, mainFieldValue, mergeFieldValue], index) => {
              const isAdded = update.findIndex(([f]) => f === field) > -1;

              return (
                <Table.Row key={`merged-entity-field-${index}`}>
                  <InfoField field={field} fields={fields} values={mergedEntity} />
                  <Table.Cell style={{ maxWidth: '200px', overflowWrap: 'break-word' }}>
                    {!!mainFieldValue && mainFieldValue !== mergeFieldValue && mergeFieldValue && (
                      <>
                        <Popup
                          trigger={
                            <Icon
                              name={isAdded ? 'check circle' : 'arrow left'}
                              color={isAdded ? 'green' : 'red'}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                const newUpate: [Field, string][] = isAdded
                                  ? update.filter(([f]) => f !== field)
                                  : [...update, [field, mergeFieldValue]];

                                setUpdate(newUpate);
                              }}
                            />
                          }
                          content={t('entityViewer.merge_modal_popup')}
                        />{' '}
                        {mergeFieldValue}
                      </>
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={mergeEntities} disabled={!entities.length}>
          {t('entityViewer.merge')}
        </Button>
        <Button negative onClick={onClose}>
          {t('GENERAL_CANCEL')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default React.memo(EntityMergeModal);
