import React from 'react';
import { Label, Popup } from 'semantic-ui-react';

import type { FC } from 'react';

import * as styles from './ArticleItemTags.style';
import Tag from 'src/Components/generic/Tag';

import type { Tag as TagData } from 'src/types/Tag';

interface Props {
  keyPrefix: string;
  tags: TagData[];
}

const TagsList: FC<Props> = ({ keyPrefix, tags }) => (
  <div style={styles.flexContainer}>
    {tags.map((tag, index) => (
      <div key={`${keyPrefix}-${tag.name}-${index}`} style={styles.paddedContainer}>
        <Tag tag={tag} />
      </div>
    ))}
  </div>
);

const ArticleItemsTags: FC<Props> = ({ keyPrefix, tags }) => (
  <div style={styles.flexContainer}>
    <TagsList keyPrefix={keyPrefix} tags={tags.slice(0, 3)} />
    {tags.length > 3 && (
      <Popup
        on={'hover'}
        trigger={
          <span style={styles.paddedContainer}>
            <Label>...</Label>
          </span>
        }
      >
        <TagsList keyPrefix={`${keyPrefix}-popup`} tags={tags} />
      </Popup>
    )}
  </div>
);

export default React.memo(ArticleItemsTags);
