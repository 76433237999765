import iziToast from 'izitoast';
import { filter } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoSizer, List } from 'react-virtualized';
import { Accordion, Icon } from 'semantic-ui-react';

import type { FC } from 'react';
import type { ListRowRenderer } from 'react-virtualized';

import TicketListItem from 'src/Components/ticketList/TicketListItem/TicketListItem';

import type { Channel } from 'src/types/Channel';
import type { LinkedTicket } from 'src/types/LinkedTickets';
import type { Priority } from 'src/types/Priority';
import type { Tag } from 'src/types/Tag';
import type { TicketListTicket } from 'src/types/Ticket';
import type { TicketType } from 'src/types/TicketType';
import type { PersonalData, User } from 'src/types/User';

import 'react-virtualized/styles.css';

interface TicketListProps {
  ticketListId: string;
  active: boolean;
  index: number;
  tickets: (TicketListTicket | LinkedTicket)[];
  title: string;
  ticketTypes: TicketType[];
  tags: Tag[];
  channels: Channel[];
  priorities: Priority[];
  removeTicketLinkingFromTicket: (id?: number, targetId?: number) => void;
  usersList: User[];
  userData: PersonalData;

  onClick(index: number): void;
}

const getTags = (ticketTags: NonNullable<TicketListTicket['tags']>, allTags: Tag[]) =>
  allTags.filter((tag) => ticketTags.includes(tag.id));
const getChannelName = (id: number, channels: Channel[]) => channels.filter((channel: Channel) => channel.id === id)[0];

const getPriorityName = (priorityGrade: number, priorities: Priority[]) =>
  priorities.filter((priority: Priority) => priority.value === priorityGrade)[0];

const TicketList: FC<TicketListProps> = ({
  ticketListId,
  active,
  index,
  tickets: reduxTickets,
  title: originalTitle,
  ticketTypes,
  tags: allTags,
  channels,
  priorities,
  removeTicketLinkingFromTicket,
  usersList,
  userData,
  onClick
}) => {
  const { t } = useTranslation();
  const [tickets, setTickets] = useState(reduxTickets);

  useEffect(() => {
    setTickets(reduxTickets);
  }, [reduxTickets]);

  const removeTicketLinks = (id?: number, targetId?: number) => {
    iziToast.question({
      timeout: 20000,
      close: false,
      overlay: true,
      id: 'question',
      zindex: 999,
      backgroundColor: 'salmon',
      overlayClose: true,
      title: t('REMOVE_TICKET_LINK_PROMPT_TITLE'),
      message: t('REMOVE_TICKET_LINK_PROMPT_MESSAGE'),
      position: 'center',
      buttons: [
        [
          `<button><b>${t('YES')}</b></button>`,
          (instance, toast) => {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            removeTicketLinkingFromTicket(id, targetId);
          },
          true
        ],
        [
          `<button>${t('GENERAL_CANCEL')}</button>`,
          function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
          },
          false
        ]
      ]
    });
  };

  const rowRenderer: ListRowRenderer = ({
    key,
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
    parent // Props of parent, eg. tickets, channels
  }) => {
    const ticket = tickets[index] as TicketListTicket;
    const tags = ticket.tags ? getTags(ticket.tags, allTags) : [];

    return (
      <div key={key} style={style}>
        <TicketListItem
          type={ticket.type}
          priority={getPriorityName(ticket.priority, priorities)}
          key={index}
          index={index}
          selected={false}
          ticketTypes={parent.props.ticketTypes}
          dueDate={ticket.dueDate}
          active={parent.props.activeTicket === ticket.id}
          tags={tags}
          id={ticket.id}
          channel={getChannelName(ticket.channel, parent.props.channels)}
          title={ticket.title}
          created={ticket.created}
          taskType={ticket.taskType}
          status={ticket.status}
          delegates={ticket.delegates || ticket.delegatedTo}
          usersList={usersList}
          touched={ticket.touched}
          // FIXME: why not in ticket type?
          to={ticket['to']}
          from={ticket['from']}
          removeTicketLinks={removeTicketLinks}
          currentUserData={userData}
          originalContact={ticket.originalContact}
          originalDirection={ticket.originalDirection}
          lastContactAddress={ticket.lastContactAddress}
        />
      </div>
    );
  };

  //Doing accordion  switch without  debounce
  const onClickChangeAccordion = () => {
    if (index !== null) {
      onClick(index);
    }
  };

  const actualTickets = tickets.filter((ticket) => Boolean(ticket.id));
  const icon = <Icon name={active ? 'chevron down' : 'chevron right'} />;
  const title = `${originalTitle} (${actualTickets.length})`;

  return (
    <React.Fragment>
      <Accordion.Title
        className={`ticketlist ticketlist_${filter}`}
        active={active}
        onClick={onClickChangeAccordion}
        data-test-id={ticketListId}
      >
        {title} {icon}
      </Accordion.Title>
      <Accordion.Content
        data-test-id={ticketListId + 'Content'}
        active={active}
        style={{ flex: '1 1 auto', height: '25vh' }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <List
              width={width}
              height={height}
              isActive={active}
              rowCount={tickets.length}
              rowHeight={90}
              estimatedRowSize={90}
              channels={channels}
              tickets={tickets}
              ticketTypes={ticketTypes}
              rowRenderer={rowRenderer}
              overscanRowCount={5}
            />
          )}
        </AutoSizer>
      </Accordion.Content>
    </React.Fragment>
  );
};

export default React.memo(TicketList);
