import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import type { ConnectedProps } from 'react-redux';
import type { AnyAction } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';

import AccordionHeader from '../AccordionHeader';
import { useWidgetView } from './WidgetViewContext';
import { activateContentListTab, addContentListTab } from 'src/actions/CaseListActions';
import {
  fetchTicketsByEntity,
  getTicketEntityById,
  removeEntityFromCase,
  updateEntityDetails
} from 'src/actions/ticketsActions';
import CustomerContainer from 'src/Components/Customer/CustomerContainer';
import ErrorBoundary from 'src/ErrorBoundary';
import { selectActiveTicket, selectActiveTicketType, selectSortedEntities } from 'src/selectors/rootStateSelectors';

import type { UpdateEntityDetail } from 'src/api/TicketsApi';
import type { State } from 'src/types/initialState';
import type { FieldSet } from 'src/types/TicketType';

interface CustomerInfoProps extends ConnectedProps<typeof connector> {
  widgetOpen: boolean;
  itemsClosed?: boolean;

  fireSearch(value: any, type: string): void;
}

const CustomerInfo = ({
  ticket,
  widgetOpen,
  itemsClosed,
  fireSearch,
  // redux
  userData,
  entitiesByTypeMap,
  selectedTicketType,
  getEntityById,
  updateEntityDetails,
  removeEntityFromCase,
  onSearchPreviousTickets
}: CustomerInfoProps) => {
  const fieldSets = selectedTicketType?.fieldSets || [];
  const { isSingleWidgetView, setSelectedWidget } = useWidgetView();

  useEffect(() => {
    if (Object.keys(entitiesByTypeMap).length === 0) {
      setSelectedWidget('AttachEntityById');
    }
  }, []);

  return (
    <>
      {Object.keys(entitiesByTypeMap).map((entityKey, idx) => {
        const fields =
          fieldSets.find(({ entityTypes }: FieldSet) => !!entityTypes?.includes?.(entityKey)) ??
          fieldSets.find(({ id }: FieldSet) => id === entityKey) ??
          fieldSets.find(({ id }: FieldSet) => id === 'customerInfo');

        return (
          <ErrorBoundary key={`case-customer-info-error-boundary-${idx}`}>
            <div className={'customer-container-color'}>
              <AccordionHeader
                as="h4"
                active={widgetOpen}
                title={fields?.displayName || ''}
                label={entitiesByTypeMap[entityKey].length}
                // title="Client Information"
                icon="address card"
                key={`case-customer-info-${idx}`}
                id={`case-customer-info-${idx}`}
                alwaysOpen={isSingleWidgetView}
              >
                {entitiesByTypeMap[entityKey].map((entity, idx) => {
                  return (
                    <CustomerContainer
                      key={`case-customer-container-${idx}`}
                      fireSearch={fireSearch}
                      generalDisable={!userData.permissions.includes('searchEntities')}
                      getEntityById={getEntityById}
                      personalData={userData}
                      collapsed={!!itemsClosed}
                      onDetachEntity={(body: { _id: string; _type: string }) => {
                        removeEntityFromCase(ticket.id, body);
                      }}
                      onSave={(updateArgs: UpdateEntityDetail) => {
                        updateEntityDetails(ticket.id, ticket.taskType, updateArgs);
                      }}
                      onSearchPreviousTickets={onSearchPreviousTickets}
                      fields={(fields && fields[fields.id]) || []}
                      additionalCustomerFieldSets={fields?.additionalFieldSets}
                      values={[entity] || []}
                      task={ticket}
                      entityType={entityKey}
                    />
                  );
                })}
              </AccordionHeader>
            </div>
          </ErrorBoundary>
        );
      })}
    </>
  );
};

const connector = connect(
  (state: State) => ({
    userData: state.userData,
    ticket: selectActiveTicket(state)!,
    entitiesByTypeMap: selectSortedEntities(state),
    selectedTicketType: selectActiveTicketType(state)
  }),
  (dispatch: ThunkDispatch<State, any, AnyAction>) => ({
    getEntityById: (ticketId: string, taskType: string, entityId: string, entityType: string) => {
      dispatch(getTicketEntityById(ticketId, taskType, entityId, entityType));
    },
    removeEntityFromCase: (
      ticketId: string,
      body: {
        _id: string;
        _type: string;
      }
    ) => {
      dispatch(removeEntityFromCase(ticketId, body));
    },
    updateEntityDetails: (ticketId: string, taskType: string, updateArgs: UpdateEntityDetail) => {
      dispatch(updateEntityDetails({ ticketId, taskType, updateArgs }));
    },
    onSearchPreviousTickets: (entityId: string, entityType: string) => {
      dispatch(addContentListTab(entityId, 'TAB_NAME_CUSTOMER #' + entityId.toString().substring(0, 3), 'tickets'));
      dispatch(activateContentListTab(entityId, 'tickets'));
      dispatch(fetchTicketsByEntity(entityId, entityId, entityType));
    }
  })
);

export default connector(CustomerInfo);
