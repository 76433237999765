import { t } from 'i18next';
import iziToast from 'izitoast';
import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { Form, Icon } from 'semantic-ui-react';

import type { FC } from 'react';

interface ForgotPasswordProps {
  handleForgottenPassword: (email: string) => Promise<any>;
  isUserAuthenticated: boolean;
}

const ForgotPassword: FC<ForgotPasswordProps> = ({ isUserAuthenticated, handleForgottenPassword }) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    setIsLoading(true);

    handleForgottenPassword(email).then((result: any) => {
      if (result.response && result.response.data.msg === 'NOT_ALLOWED_FEATURE') {
        iziToast.error({
          message: `${t('NOT_ALLOWED_FEATURE')}`,
          position: 'bottomCenter'
        });
      } else {
        iziToast.info({
          message: `${t('LOGIN_FORGOTPASSWORD_REQUEST_SUBMITTED')}`,
          position: 'bottomCenter'
        });
      }

      setIsLoading(false);
    });
  };

  return (
    <div className="loginWrapper">
      {isUserAuthenticated ? (
        <Redirect to="/" />
      ) : (
        <Translation ns="translations">
          {(tr) => (
            <div className="loginForm">
              <div className="logo" />

              <Form style={{ width: '240px', paddingTop: '10px', margin: '10px 0' }}>
                <p style={{ color: 'white' }}>{tr('LOGIN_FORGOTPASSWORD_INSTRUCTIONS')}</p>
                <Form.Input name="email" icon="mail" placeholder={tr('PLACEHOLDER_EMAIL')} onChange={handleChange} />
                <Link to="/login">
                  {' '}
                  <Form.Button style={{ marginBottom: '10px' }} fluid={true}>
                    {tr('LOGIN_FORGOTPASSWORD_RETURN')}
                  </Form.Button>
                </Link>
                <Form.Button labelPosition="right" icon fluid onClick={handleSubmit} disabled={isLoading}>
                  {tr('LOGIN_FORGOTPASSWORD_SEND')}
                  <Icon name="send" />
                </Form.Button>
              </Form>
            </div>
          )}
        </Translation>
      )}
    </div>
  );
};

export default ForgotPassword;
