import {
  faArrowUpRightFromSquare,
  faBookOpen,
  faChartColumn,
  faGear,
  faHouse,
  faTableList
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';

import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import type { ReactNode } from 'react';
import type { MenuItem } from 'semantic-ui-react';

import { ListOfPresetFilters } from './ListOfPresetFilters';

interface MenuItem {
  icon: IconDefinition;
  text: string;
  href?: string;
}

const menuItems: MenuItem[] = [
  {
    icon: faHouse,
    text: 'Main view',
    href: '/'
  },
  {
    icon: faBookOpen,
    text: 'Knowledge base',
    href: '/infopage'
  },
  {
    icon: faArrowUpRightFromSquare,
    text: 'Export data'
  },
  {
    icon: faChartColumn,
    text: 'Reporting',
    href: '/reporting'
  },
  {
    icon: faTableList,
    text: 'Aggregate view',
    href: '/aggregate'
  },
  {
    icon: faGear,
    text: 'Settings',
    href: '/settings'
  }
];

const UnifiedMenuAndFilters = () => {
  const [isMinimised, setIsMinimised] = useState(false);

  const wrapWithLink = (item: MenuItem, node: ReactNode) => {
    return (
      <Link to={item.href!} className="dropdown-link">
        {node}
      </Link>
    );
  };

  const Item = (props: { item: MenuItem }) => {
    const node = (
      <div className="layout-desktop-unified-menu-item">
        <FontAwesomeIcon icon={props.item.icon} size='lg' />
        <div className="layout-desktop-unified-menu-item-text">{props.item.text}</div>
      </div>
    );

    return props.item.href ? wrapWithLink(props.item, node) : node;
  };

  return (
    <Translation>
      {() => (
        <div
          className="layout-desktop-unified-menu"
          onPointerEnter={() => setIsMinimised(false)}
          onPointerLeave={() => setIsMinimised(true)}
        >
          <div style={{ padding: '15px' }}>
            {menuItems.map((item) => (
              <Item item={item} />
            ))}
          </div>
          <Divider />
          {!isMinimised && (
            <div>
              <ListOfPresetFilters />
            </div>
          )}
        </div>
      )}
    </Translation>
  );
};

export default UnifiedMenuAndFilters;
