import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Icon, Label, Popup } from 'semantic-ui-react';

import type { FC, SyntheticEvent } from 'react';
import type { SemanticICONS } from 'semantic-ui-react';

import Tag from 'src/Components/generic/Tag';

import type { State } from 'src/types/initialState';
import type { Tag as TagType } from 'src/types/Tag';

interface TagProps {
  onClose?: React.EventHandler<SyntheticEvent>;
  tag: TagType;
}

const TagPopup: FC<TagProps> = ({ tag, onClose }) => {
  const { t } = useTranslation();
  const categories = useSelector(({ categories }: State) => categories);
  const tagCategories = categories.filter(({ id }) => tag.categories.includes(`CAT${id}`));

  return (
    <Popup
      disabled={!tagCategories.length}
      hoverable
      on="hover"
      position="top center"
      trigger={<Tag tag={tag} onClose={onClose} />}
    >
      <Popup.Header>{t('GENERAL_CATEGORIES')}</Popup.Header>
      <Popup.Content>
        {tagCategories.map((category) => (
          <Label
            key={category.name}
            style={{
              backgroundColor: category.bgColor,
              color: category.fontColor,
              margin: '1.5px'
            }}
          >
            <Icon name={category.icon as SemanticICONS} />
            {category.name}
          </Label>
        ))}
      </Popup.Content>
    </Popup>
  );
};

export default TagPopup;
