import React from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Image } from 'semantic-ui-react';

import type { CSSProperties, FC } from 'react';

import { getProfileName } from './utils';
import { getUserImage } from 'src/Utilities/images';

import type { State } from 'src/types/initialState';
import type { User } from 'src/types/User';

export type UserAvatarSize = '24' | '26' | '32' | '35' | '40' | '50' | '70';

interface UserAvatarProps {
  UID?: string;
  className?: string;
  imgClassName?: string;
  round?: boolean;
  size: UserAvatarSize;
  style?: CSSProperties;
  user?: User;
}

const UserAvatar: FC<UserAvatarProps> = ({ UID, round, size, user: propUser, ...props }) => {
  const { t } = useTranslation();
  const stateUser = useSelector((state: State) => state.usersList.usersList.find((user) => user.UID === UID));
  const user = propUser || stateUser;

  if (!user) {
    return <span>{t('ERROR_HAPPENED')}</span>;
  }

  const name = getProfileName(user);
  const { profileImage, avatarColor } = user?.profile || {};
  const hasImage = profileImage && !profileImage.includes('blank');
  const largeImage = parseInt(size) > 40;

  return hasImage && !avatarColor ? (
    <Image alt={name} circular={round} height={size} src={getUserImage(profileImage, largeImage)} />
  ) : (
    <Avatar name={name} round={round} size={size} color={avatarColor} textSizeRatio={2} {...props} />
  );
};

export default UserAvatar;
