import React from 'react';

import { useFocusMode } from '../FocusMode/FocusModeContext';
import PhoneIntegrations from '../PhoneIntegrations';
import TicketListTab from '../ticketList/TicketListTab';
import TicketListTabBarContainer from 'src/containers/TicketListTabBarContainer';
import ErrorBoundary from 'src/ErrorBoundary';
import { getUrlSearchParam } from 'src/Utilities/helper';

const TicketsSidebar = () => {
  const { isFocusMode } = useFocusMode();

  const divStyle = {
    maxWidth: isFocusMode ? '0px' : '100%',
    overflow: 'hidden',
    transition: 'max-width 0.5s ease'
  };

  const hideTicketListPaths = ['/settings', '/entities', '/infopage'];
  const onSettingsPage = hideTicketListPaths.some((path) => location.pathname.includes(path));

  if (getUrlSearchParam('hideNavigation')) {
    return null;
  }

  return (
    <div
      className="sideBar"
      style={{ display: onSettingsPage ? 'none' : 'flex', ...divStyle, backgroundColor: 'white', borderRight: '0' }}
    >
      <ErrorBoundary>
        <TicketListTabBarContainer />
      </ErrorBoundary>

      <div className="flexSideView">
        <ErrorBoundary>
          <TicketListTab contentType="tickets" />
        </ErrorBoundary>
        <ErrorBoundary>
          <PhoneIntegrations />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default TicketsSidebar;
