import classNames from 'classnames';
import { partition, uniq } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { FC, ReactNode } from 'react';

import styles from './CommentsAccordion.module.css';
import { getLastComment } from 'src/Components/Utilities/comments';
import { botButtonClickedState } from 'src/Utilities/comments';

import type { Direction } from 'src/types/Sorting';
import type { Comment } from 'src/types/Ticket';

// We select 4 comments: first the normal ones and the rest are filled with automatic comments
const chooseCommentsToShowCollapsed = (comments: Comment[]): string[] => {
  const [normalComments, automaticComments] = partition(comments, ({ type }) => type !== 'automatic');

  return [normalComments, automaticComments]
    .reduce((acc, curr) => uniq([...acc, ...curr.slice(0, 2), ...curr.slice(-2)]), [])
    .slice(0, 4)
    .map(({ id }) => id);
};

interface Props {
  comments: Comment[];
  direction: Direction;
  disableCollapsing: boolean;
  singleLineComments?: boolean;
  setActiveSingleLineComment?: (id?: string) => void;
  activeComment?: string;
  renderUserComment: (
    comment: Comment,
    isLastComment: boolean,
    isLastExternalComment: boolean,
    botButtonClickedState: undefined | Comment,
    isList: boolean
  ) => ReactNode | null;
}

const CommentsAccordion: FC<Props> = ({
  comments,
  renderUserComment,
  disableCollapsing,
  direction,
  setActiveSingleLineComment: setActiveComment,
  activeComment,
  singleLineComments
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(!disableCollapsing);
  const { t } = useTranslation();

  const collapsedCommentsIds = chooseCommentsToShowCollapsed(comments);
  const lastExternalComment = getLastComment(comments, true, direction);
  const lastComment = getLastComment(comments, false, direction);

  const renderExpandButton = (text: string) => {
    if (disableCollapsing) return null;

    return (
      <p onClick={() => setIsCollapsed(!isCollapsed)} style={{ textAlign: 'center', cursor: 'pointer' }}>
        <span className="label ui" style={{ marginTop: '5px' }}>
          {text}
        </span>
      </p>
    );
  };

  return (
    <>
      {renderExpandButton(
        isCollapsed ? `⇣ ${comments.length - 4} ${t('HIDDEN_MESSAGES_POSTFIX')} ⇣` : `⇣ ${t('HIDE_MESSAGES')} ⇣`
      )}

      {comments.map((comment, index) => {
        const isLastComment = comment.id === lastComment?.id;
        const isLastExternalComment = comment.id === lastExternalComment?.id;

        if (isCollapsed && !collapsedCommentsIds.includes(comment.id)) {
          return null;
        }

        const isActive = (typeof activeComment === 'undefined' && index === 0) || comment.id === activeComment;

        return (
          <div
            onClick={() => setActiveComment?.(comment.id)}
            className={classNames(styles.comment, singleLineComments && styles.isSingleLine, isActive && styles.active)}
            key={comment.id}
          >
            {isCollapsed &&
              collapsedCommentsIds[2] === comment.id &&
              renderExpandButton(`⇣ ${t('DISPLAY_ALL_MESSAGES')} (${comments.length - 4}) ⇣`)}

            {renderUserComment(
              comment,
              isLastComment,
              isLastExternalComment,
              botButtonClickedState(comment, comments),
              true
            )}
          </div>
        );
      })}

      {!isCollapsed && renderExpandButton(`⇡ ${t('HIDE_MESSAGES')} ⇡`)}
    </>
  );
};

export default CommentsAccordion;
