import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Input } from 'semantic-ui-react';

import type { FC } from 'react';

interface Props {
  value?: number | string;
  onChange: (value: number) => void;
}

const TimeInputWithDropdown: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();

  const options: { text: string; value: number }[] = [
    { text: t('GENERAL_SECONDS'), value: 1 },
    { text: t('GENERAL_MINUTES'), value: 60 },
    { text: t('GENERAL_HOURS'), value: 60 * 60 },
    { text: t('GENERAL_DAYS'), value: 24 * 60 * 60 },
    { text: t('GENERAL_WEEKS'), value: 7 * 24 * 60 * 60 },
    { text: t('GENERAL_MONTHS'), value: 30 * 24 * 60 * 60 },
    { text: t('GENERAL_YEARS'), value: 365 * 24 * 60 * 60 }
  ];
  const [seconds, setSeconds] = useState(0);
  const [multiplier, setMultiplier] = useState(60 * 60);

  useEffect(() => {
    if (!value) {
      setSeconds(0);
      setMultiplier(60 * 60);

      return;
    }

    const multiplierOption = [...options].reverse().find((option) => +value % option.value === 0);

    if (multiplierOption) {
      setMultiplier(multiplierOption.value);
      setSeconds(+value / multiplierOption.value);
    }
  }, [value]);

  return (
    <Input
      labelPosition="right"
      label={
        <Dropdown
          options={options}
          value={multiplier}
          onChange={(event: React.SyntheticEvent<HTMLElement>, data: any) => {
            setMultiplier(data.value);
            onChange(seconds * data.value);
          }}
        />
      }
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setSeconds(+event.target.value);
        onChange(+event.target.value * multiplier);
      }}
      type="number"
      value={seconds || ''}
    />
  );
};

export default TimeInputWithDropdown;
